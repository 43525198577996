import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useCookies } from 'react-cookie';

import { AppBar, Toolbar, Typography, Stack, Switch, Tooltip, IconButton } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { createTheme } from '@mui/material/styles';

import icon from '../img/icon.jpg';

import { datePlusMonths } from './nav_bar_controllers/expires_cookies.js';
import { ThemeContext } from '../app.js';
import theme from '../config/theme';

/**
 * Para setear el swith de acuerdo a al lenguaje obtenido de forma automatica.
 * @param {String} language 
 * @returns {Boolean} Retorna true si es está seteado.
 */ 
function setSwith(language) {
    return language.includes('es')
};

/**
 * Cambia el lenguaje.
 * @param {String} language - Lenguaje a cambiar. 
 */
function handleLanguage(language) {
    if (language.includes('es'))
        i18next.changeLanguage('en');

    if (language.includes('en'))
        i18next.changeLanguage('es');
};

/**
 * Componente de React de la barra de navegación.
 * @returns {React} Componente
 */
export default function NavBar() {
    const { t, i18n } = useTranslation();
    const checked = setSwith(i18n.language);

    const { stateTheme, setStateTheme } = useContext(ThemeContext);

    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(['themeCookie']);

    function changeTheme(mode) {
        theme.palette.mode = mode === 'dark' ? 'light' : 'dark';
        setCookie('themeCookie', mode === 'dark' ? 'light' : 'dark',
            {
                sameSite: 'strict',
                expires: datePlusMonths(1)
            });
        setStateTheme(createTheme(theme));
    };

    return (
        <AppBar position='sticky'>
            <Toolbar>
                <img src={icon} style={{ height: 50, borderRadius: '10%' }} alt='banner' />

                <Typography variant='h6' color='inherit' noWrap
                    sx={{ padding: '1rem' }}>
                    {t('general:title_navbar')}
                </Typography>
                <Stack alignItems='center'
                    sx={{ marginLeft: 'auto' }}>
                    <Stack direction='row' alignItems='center'>
                        <Tooltip title={t('general:languaje_english')}>
                            <Typography>Eng</Typography>
                        </Tooltip>
                        <Switch checked={checked} onChange={() => handleLanguage(i18n.language)}></Switch>
                        <Tooltip title={t('general:languaje_spanish')}>
                            <Typography>Spa</Typography>
                        </Tooltip>
                    </Stack>

                    <Stack direction='row' alignItems='center'>
                        <Typography>{t('general:theme')}</Typography>

                        <IconButton
                            onClick={() => changeTheme(stateTheme.palette.mode)}>
                            {stateTheme.palette.mode === 'dark' ?
                                <Brightness7Icon /> :
                                <Brightness4Icon sx={{ color: 'white', padding: '0' }} />}
                        </IconButton>
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    )
};
