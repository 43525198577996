import { useTranslation } from "react-i18next";

import { Container, Card, Typography, CardMedia, CardContent, Stack, CardActions, Link } from "@mui/material";

import perfil from '../img/perfil.jpg';

/**
 * Componente de React que muestra los detalles sobre mi.
 * @returns {React} Componente.
 */
export default function About() {
    const { t } = useTranslation();

    return (
        <Container align='center'>
            <Typography align='center' variant='h3'
                sx={{ paddingTop: '4rem', paddingBottom: '3rem' }}>
                {t('general:aboutme_title')}
            </Typography>

            <Card sx={{ padding: '1rem' }}>
                <Stack direction={{ xs: 'column', sm: 'row', md: 'row' }}>
                    <CardMedia
                        style={{ borderRadius: '50%', minWidth: '15rem', maxWidth: '20rem', margin: 'auto' }}
                        component="img"
                        image={perfil}
                        alt="perfil"
                        autoPlay
                        sx={{ border: '1px solid #f3f6f4' }} />
                    <Stack sx={{ margin: 'auto' }}>
                        <CardContent>
                            <Typography>
                                {t('general:aboutme_description')}
                            </Typography>
                        </CardContent>
                        <CardActions sx={{ margin: 'auto' }}>
                            <Link href='#id_contact'>
                                {t('general:aboutme_contact')}
                            </Link>
                        </CardActions>
                    </Stack>
                </Stack>
            </Card>
        </Container>
    )
};