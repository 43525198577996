/**
 * La funcion devuelve la fecha más la cantidad de meses especificadas.
 * Por defecto suma un solo mes.
 * @param {Number} months - Cantidad de meses.
 * 
 * @returns {Date} Fecha más la cantidad de meses especificadas.
 */
export function datePlusMonths(months) {
    if (!months)
        months = 1;

    if (isNaN(months))
        throw new Error("Months is not a number.");

    let date = new Date();
    date.setMonth(date.getMonth() + months);
    return date;
};
