import { useTranslation } from "react-i18next";

import { Card, CardActions, CardContent, CardMedia, Grid, Typography, Container, Link, Tooltip } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import HttpIcon from '@mui/icons-material/Http';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import React from "react";

/**
 * Componente de React que muestra en formato el Proyecto.
 * @param {Object} props.proyects - Proyecto a mostrar
 * @returns {React} Componente.
 */
export default function Proyect({ proyects }) {
    const { t } = useTranslation();

    return (
        <Grid container spacing={4}>
            {proyects.map(proyect => (
                <Grid item key={proyect.key} xs={12} sm={6} md={6} lg={6} xl={4}>
                    <Card
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '1rem'
                        }}>
                        <CardMedia
                            component="img"
                            image={proyect.img}
                            alt="random"
                            autoPlay />

                        <CardContent sx={{ flexGrow: 1 }}>
                            <Typography gutterBottom variant="h5" component="h2" align='center'>
                                {proyect.title}
                            </Typography>
                            <Typography align='center'>
                                {proyect.description}
                            </Typography>
                        </CardContent>

                        <CardActions>
                            <Container align='center'>
                                {proyect.urlVideo !== "" ? <Link href={proyect.urlVideo} target="_blank" rel="noreferrer"
                                    sx={{ padding: '0.5rem' }}>
                                    <Tooltip title={`${t('general:tool_tip_video')}`}>
                                        <SlideshowIcon fontSize='large' />
                                    </Tooltip>
                                </Link> : null}

                                {proyect.urlGit !== "" ? <Link href={proyect.urlGit} target="_blank" rel="noreferrer"
                                    sx={{ padding: '0.5rem' }}>
                                    <Tooltip title={`${t('general:tool_tip_github')}`}>
                                        <GitHubIcon fontSize='large' />
                                    </Tooltip>

                                </Link> : null}
                                {proyect.urlHttp !== "" ? <Link href={proyect.urlHttp} target="_blank" rel="noreferrer"
                                    sx={{ padding: '0.5rem' }}>
                                    <Tooltip title={`${t('general:tool_tip_url')}`}>
                                        <HttpIcon fontSize='large' />
                                    </Tooltip>
                                </Link> : null}
                            </Container>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
};