import { Suspense, createContext, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { useCookies } from 'react-cookie';

import './config/languajes/i18n.js'

import NavBar from './componets/nav_bar.jsx';
import MainDescription from './componets/main_description.jsx';
import Proyects from './componets/proyects.jsx';
import Footer from './componets/footer.jsx';
import Tecnologies from './componets/tecnologies.jsx';
import About from './componets/about.jsx';

import theme from './config/theme.js'
export const ThemeContext = createContext(null);

/**
 * Componente de Reac que engrloba todo el sitio.
 * @returns {React} Componente.
 */
function App() {
  // Se toma color del navegador
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // Se toma cookie del theme
  const [cookies] = useCookies(['themeCookie']);

  // Se crea estado con el theme ya configurado
  const [stateTheme, setStateTheme] = useState(
    createTheme({
      ...theme,
      palette: {
        mode: cookies.hasOwnProperty('themeCookie') ? cookies.themeCookie : prefersDarkMode ? 'dark' : 'light'
      }
    })
  );

  return (
    // Suspense es para la config del lenguaje
    <Suspense fallback={null}>
      {/* Se pasa stateTheme, setStateTheme para poder editar estado desde NavBar */}
      <ThemeContext.Provider value={{ stateTheme, setStateTheme }} >
        <ThemeProvider theme={stateTheme}>
          <CssBaseline />
          <NavBar />
          <MainDescription />
          <About />
          <Tecnologies />
          <Proyects />
          <Footer />
        </ThemeProvider>
      </ThemeContext.Provider>
    </Suspense>
  );
}

export default App;
