import * as React from 'react';

import { Box, Typography, Link, Container, Button } from '@mui/material';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';

import { useTranslation } from "react-i18next";
import cv from '../data/cv.pdf';

/**
 * Componente de React que muestra los detalles de contacto.
 * @returns {React} Componente.
 */
export default function Footer() {
    const { t } = useTranslation();

    return (
        <Box id='id_contact' sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
            <Typography variant="h4" align="center" gutterBottom>
                {t('general:contact_title')}
            </Typography>
            <Container align="center">
                <Button>
                    <Link href='https://www.linkedin.com/in/fer-bec/' target="_blank" rel="noreferrer">
                        <LinkedInIcon fontSize='large' />
                    </Link>
                </Button>
                <Button>
                    <Link href='https://github.com/ferb0' target="_blank" rel="noreferrer">
                        <GitHubIcon fontSize='large' />
                    </Link>
                </Button>
                <Button>
                    <Link href='mailto:ferb+contacto@e.email' target="_blank" rel="noreferrer">
                        <EmailIcon fontSize='large' />
                    </Link>
                </Button>
                <Button>
                    <Link href={cv} target="_blank" rel="noreferrer" underline="none">
                        <Typography variant='h5' sx={{ fontWeight: 'bold',   paddingBottom: '0.4rem' }}>
                            CV
                        </Typography>
                    </Link>
                </Button>
            </Container>

            <Typography
                variant="subtitle1"
                align="center"
                color="text.secondary"
                component="p">
                {t('general:footer_gratitude')}
            </Typography>
        </Box>
    )
}