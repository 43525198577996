import * as React from 'react';

import { useTranslation } from "react-i18next";

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import banner from '../img/banner.jpg';

/**
 * Componente de React que contiene la descripción general.
 * @returns {React} Componente
 */
export default function MainDescription() {
    const { t } = useTranslation();

    return (
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 8,
                    pb: 6,
                    backgroundImage: `url(${banner})`,
                    backgroundSize: "cover",
                }}>
                <Container maxWidth="sm"
                sx={{
                    padding: 0,
                    marginLeft: 0,
                    }}>
                    <Typography
                        component="h2"
                        variant="h2"
                        align="center"
                        color="common.white"
                        gutterBottom
                        sx={{margin: '0rem'}}>
                        Fernando Becerra
                    </Typography>
                    <Typography component="h1" variant="h6" align="center" color="common.white" paragraph>
                        {t('general:main_subtitle')}
                    </Typography>
                    <Typography variant="h5" align="center" color="common.white" paragraph>
                        {t('general:main_description')}
                    </Typography>
                    <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="center">
                    </Stack>
                </Container>
            </Box>
    )
}