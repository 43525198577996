import { Typography, Card, Stack, Link } from '@mui/material';

import { useTranslation } from "react-i18next";

/**
 * Componente de React que contiene a todos las tecnologías conocidas.
 * @returns {React} Componente.
 */
export default function Tecnologies() {
    const { t } = useTranslation();

    return (
        <>
            <Typography align='center' variant='h3' sx={{ paddingTop: '4rem', paddingBottom: '4rem' }}>
                {t('general:tecnologies_title')}
            </Typography>

            <Card sx={{ display: 'table', margin: 'auto', padding: '2rem' }}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 2, md: 4 }}>
                    <Link href="https://www.w3.org/html/" target="_blank" rel="noreferrer"> <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg" alt="html5" width="50" height="50" /></Link>
                    <Link href="https://www.w3schools.com/css/" target="_blank" rel="noreferrer"> <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg" alt="css3" width="50" height="50" /></Link>
                    <Link href="https://git-scm.com/" target="_blank" rel="noreferrer"> <img src="https://www.vectorlogo.zone/logos/git-scm/git-scm-icon.svg" alt="git" width="50" height="50" /></Link>
                </Stack>

                <Stack direction="row" justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 2, md: 4 }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                        <Link href="https://nodejs.org" target="_blank" rel="noreferrer"> <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original-wordmark.svg" alt="nodejs" width="50" height="50" /></Link>
                        <Link href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="_blank" rel="noreferrer"> <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg" alt="javascript" width="50" height="50" /></Link>
                        <Link href="https://reactjs.org/" target="_blank" rel="noreferrer"> <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg" alt="react" width="50" height="50" /></Link>
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                        <Link href="https://redux.js.org/" target="_blank" rel="noreferrer"> <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/redux/redux-original.svg" alt="redux" width="50" height="50" /></Link>
                        <Link href="https://expressjs.com/" target="_blank" rel="noreferrer"> <img src="https://user-images.githubusercontent.com/27827755/211893663-429a1545-0d5b-4747-b053-8a1015380d92.png" alt="express" width="50" height="50" /></Link>
                        <Link href="https://sequelize.org/" target="_blank" rel="noreferrer"> <img src="https://sequelize.org/img/logo.svg" alt="sequelize" width="50" height="50" /></Link>
                        <Link href="https://www.npmjs.com/" target="_blank" rel="noreferrer"> <img src="https://codigoonclick.com/wp-content/uploads/2019/05/npm-nodejs-1024x640.jpeg" alt="npm" width="50" height="50" /></Link>
                    </Stack>
                </Stack>

                <Stack direction="row" justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 2, md: 4 }}>
                    <Link href="https://www.w3schools.com/sql/" target="_blank" rel="noreferrer"> <img src="https://upload.wikimedia.org/wikipedia/commons/8/87/Sql_data_base_with_logo.png" alt="sql" width="50" height="50" /></Link>
                    <Link href="https://www.postgresql.org" target="_blank" rel="noreferrer"> <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/postgresql/postgresql-original-wordmark.svg" alt="postgresql" width="50" height="50" /></Link>
                    <Link href="https://www.sqlite.org/" target="_blank" rel="noreferrer"> <img src="https://www.vectorlogo.zone/logos/sqlite/sqlite-icon.svg" alt="sqlite" width="50" height="50" /></Link>
                </Stack>
            </Card>
        </>
    )
}