import generalEn from './locales/en/translation.json';
import proyectsEn from './locales/en/proyects.json';
import generalEs from './locales/es/translation.json';
import proyectsEs from './locales/es/proyects.json';

import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'es'];

// Se setea configuración de lenguaje.
i18n.use(Backend) // se cargan plugins
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng,
        detection: {
            checkWhitelist: true
        },
        debug: false,
        whitelist: availableLanguages,
        resources: {
            en: {
                general: generalEn,
                proyects: proyectsEn
            },
            es: {
                general: generalEs,
                proyects: proyectsEs
            }
        }
    });

export default i18n;