// Theme global para todo el sitio.
const theme = {
    palette: {
        mode: 'light',
    },
    components: {
        // Para el color del Switch Lenguages
        MuiSwitch: {
            styleOverrides: {
                colorPrimary: {
                    "&.Mui-checked": {
                        // Controls checked color for the thumb
                        color: "#ffffff"
                    }
                },
                track: {
                    // Controls default (unchecked) color for the track
                    backgroundColor: "#ffffff",
                    ".Mui-checked.Mui-checked + &": {
                        // Controls checked color for the track
                        backgroundColor: "#ffffff"
                    }
                }
            }
        }
    }
};

export default theme;
