import { useTranslation } from "react-i18next";
import Proyect from './proyect.jsx';

import { Typography, Container } from '@mui/material';

import proyectsSoyHenrryGrupal from '../img/proyectsSoyHenrryGrupal.gif';
import proyectsSoyHenrryInvividual from '../img/proyectsSoyHenrryInvividual.png';
import proyectsWithFoosIssues from '../img/proyectsWithFoosIssues.png'

/**
 * Componente de React que contiene a todos los proyectos.
 * @returns {React} Componente.
 */
export default function Proyects() {
    const { t } = useTranslation();
    // Imagenes de los proyectos
    const proyectsImages = [proyectsSoyHenrryInvividual, proyectsSoyHenrryGrupal, proyectsWithFoosIssues];

    // Se obtiene la info traducida sobre los proyectos.
    const proyects = [];
    let i = 1;
    while (true) {
        if (t('proyects:proyects' + i + '.title') === 'proyects' + i + '.title')
            break;

        proyects.unshift({
            key: i,
            title: t('proyects:proyects' + i + '.title'),
            description: t('proyects:proyects' + i + '.description'),
            urlGit: t('proyects:proyects' + i + '.url_git'),
            urlHttp: t('proyects:proyects' + i + '.url_deploy'),
            urlVideo: t('proyects:proyects' + i + '.url_video'),
            img: proyectsImages[i - 1]
        });

        i++;
    };

    return (
        <>
            <Typography align='center' variant='h3' sx={{ paddingTop: '4rem' }}>
                {t('general:proyects_title')}
            </Typography>

            <Container key={Math.random()} sx={{ py: 8 }} maxWidth="md">
                <Proyect proyects={proyects}/>
            </Container>
        </>
    )
}